import { postShelfPerformances } from '../../services/shelf-previewer/post-shelf-performances';
import { postItemsSearch } from '../../services/shelf-previewer/post-items-search';
import { IShelfPerformance } from '../../types/shelf-performance.type';
import { IItem } from '../../types/item.type';

export const fetchShelfData = async (
  clientId: string,
): Promise<{
  performances: IShelfPerformance[];
  itemsMetadata: { [aid: string]: IItem };
}> => {
  try {
    // シェルフパフォーマンスデータを取得
    const performances = await postShelfPerformances();

    // 全てのアイテムのAIDを収集
    const allAids = new Set<string>();
    performances.forEach((performance) => {
      performance.shelves.forEach((shelf) => {
        shelf.items.forEach((item) => allAids.add(item.aid));
      });
    });

    // アイテム詳細を取得
    const itemsData = await postItemsSearch({
      clientId: clientId,
      aids: Array.from(allAids),
    });

    // アイテムメタデータを作成
    const itemsMetadata: { [aid: string]: IItem } = {};
    for (const aid in itemsData.items) {
      if (itemsData.items[aid].length > 0) {
        itemsMetadata[aid] = itemsData.items[aid][0];
      }
    }

    return { performances, itemsMetadata };
  } catch (error) {
    console.error('Error fetching shelf data:', error);
    throw error;
  }
};

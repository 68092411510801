import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { IShelf } from '../../types/shelf.type';
import ShelfTaglines from './ShelfTaglines';
import Item from './Item';
import { Checkbox, FormControlLabel } from '@mui/material';
import { ShelfUiTypeEnum } from '../../enums/shelf-ui-type.enum';
import Banner from './Banner';

interface ShelfProps {
  shelf: IShelf;
  onSelectShelf?: (shelf: IShelf, isSelected: boolean) => void;
  isSelected?: boolean;
}

const ShelfCardContainer = styled.div`
  background-color: #f9f9f9;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
  transition: box-shadow 0.3s ease;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const TaglineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
`;

const ShowMoreLink = styled.a`
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

const ItemsContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 10px;
  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
  }

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledCheckbox = styled(FormControlLabel)`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
`;

const PerformanceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
`;

const PerformanceItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PerformanceLabel = styled.span`
  font-weight: bold;
  color: #555;
  margin-bottom: 4px;
`;

const PerformanceValue = styled.span`
  color: #007bff;
  font-size: 16px;
`;

const PerformanceDetails = styled.span`
  font-size: 12px;
  color: #777;
`;

const RankingIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #ffd700;
  border-radius: 100%;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  z-index: 1;
`;

const ItemsScroll = styled.div<{ rows: number }>`
  display: inline-grid;
  grid-auto-flow: column;
  grid-template-rows: ${(props) => `repeat(${props.rows}, 1fr)`};
  gap: 10px;
  min-width: 0;
`;

const GridBannerContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr 1fr;
  grid-template-rows: 1fr 1fr auto;
  gap: 1px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
`;

const GridImage = styled.img<{ $gridArea: string }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  grid-area: ${(props) => props.$gridArea};
`;

const GridTagline = styled.div`
  grid-column: 1 / -1;
  padding: 10px;
  text-align: left;
  font-weight: bold;
  background: white;
`;

const Shelf: React.FC<ShelfProps> = ({ shelf, onSelectShelf, isSelected }) => {
  const [displayedItems, setDisplayedItems] = useState<IShelf['items']>(
    shelf.items,
  );
  const rows = shelf.sliderRows || 1;

  useEffect(() => {
    setDisplayedItems(shelf.items);
  }, [shelf.items]);

  const handleImageError = (aid: string) => {
    setDisplayedItems((prevItems) =>
      prevItems.filter((item) => item.aid !== aid),
    );
  };

  const formatPercentage = (value: number): string => {
    if (isNaN(value) || !isFinite(value)) return '0.0';
    return value.toFixed(1);
  };

  const formatCount = (value: number): string => {
    return value.toLocaleString('en-US');
  };

  const isHighlighted = (currentDate: string, prevDate: string | undefined) => {
    if (!prevDate || !currentDate) return false;
    const currentTime = new Date(currentDate).getTime();
    const prevTime = new Date(prevDate).getTime();
    const diff = currentTime - prevTime;
    return diff >= 30 * 60 * 1000; // 30分 = 30 * 60 * 1000 ミリ秒
  };

  if (displayedItems.length === 0) {
    return null;
  }

  const renderBanner = () => {
    if (
      shelf.uiType === ShelfUiTypeEnum.bannerSlider &&
      displayedItems.length >= 2
    ) {
      const bannerItems = displayedItems
        .slice(0, 10)
        .sort(() => 0.5 - Math.random())
        .slice(0, 2);
      return (
        <Banner
          shelf={shelf}
          items={bannerItems}
          tagline1={shelf.tagline1}
          tagline2={shelf.tagline2}
        />
      );
    }
    return null;
  };

  const renderGridBanner = () => {
    if (
      shelf.uiType === ShelfUiTypeEnum.gridBanner &&
      displayedItems.length >= 6
    ) {
      const gridItems = displayedItems.slice(0, 6);
      return (
        <GridBannerContainer>
          <GridImage
            src={gridItems[0].imageUrl}
            alt={gridItems[0].title}
            $gridArea="1 / 1 / 3 / 2"
          />
          <GridImage
            src={gridItems[1].imageUrl}
            alt={gridItems[1].title}
            $gridArea="1 / 2 / 2 / 3"
          />
          <GridImage
            src={gridItems[2].imageUrl}
            alt={gridItems[2].title}
            $gridArea="2 / 2 / 3 / 3"
          />
          <GridImage
            src={gridItems[3].imageUrl}
            alt={gridItems[3].title}
            $gridArea="1 / 3 / 3 / 4"
          />
          <GridImage
            src={gridItems[4].imageUrl}
            alt={gridItems[4].title}
            $gridArea="1 / 4 / 2 / 5"
          />
          <GridImage
            src={gridItems[5].imageUrl}
            alt={gridItems[5].title}
            $gridArea="2 / 4 / 3 / 5"
          />
          <GridTagline>{shelf.tagline2}</GridTagline>
        </GridBannerContainer>
      );
    }
    return null;
  };

  const renderItems = () => {
    const items = displayedItems;

    return (
      <ItemsContainer>
        <ItemsScroll rows={rows}>
          {items.map((item, index) => {
            const prevItem = index > 0 ? items[index - 1] : undefined;
            const highlighted = isHighlighted(
              item.createdAt,
              prevItem?.createdAt,
            );

            return (
              <Item
                key={`${shelf.shelfId}-${index}-${item.aid}`}
                shelf={shelf}
                item={item}
                onImageError={() => handleImageError(item.aid)}
                isHighlighted={highlighted}
              />
            );
          })}
        </ItemsScroll>
      </ItemsContainer>
    );
  };

  return (
    <ShelfCardContainer>
      {shelf.ranking && <RankingIcon>{shelf.ranking}</RankingIcon>}
      {onSelectShelf && (
        <StyledCheckbox
          control={
            <Checkbox
              checked={isSelected}
              onChange={(e) =>
                onSelectShelf && onSelectShelf(shelf, e.target.checked)
              }
              color="primary"
            />
          }
          label=""
        />
      )}
      {shelf.uiType === ShelfUiTypeEnum.gridBanner
        ? renderGridBanner()
        : renderBanner()}
      {shelf.uiType !== ShelfUiTypeEnum.bannerSlider &&
        shelf.uiType !== ShelfUiTypeEnum.gridBanner && (
          <TaglineContainer>
            <ShelfTaglines
              tagline1={shelf.tagline1}
              tagline2={shelf.tagline2}
            />
            {shelf.showMoreLink && (
              <ShowMoreLink href={shelf.showMoreLink}>もっと見る</ShowMoreLink>
            )}
          </TaglineContainer>
        )}
      {shelf.shown && (
        <PerformanceContainer>
          {shelf.shown && (
            <PerformanceItem>
              <PerformanceLabel>表示数</PerformanceLabel>
              <PerformanceValue>
                {formatPercentage(shelf.shown)}
              </PerformanceValue>
            </PerformanceItem>
          )}
          <PerformanceItem>
            <PerformanceLabel>クリック率</PerformanceLabel>
            <PerformanceValue>
              {formatPercentage(shelf.clickr)}%
            </PerformanceValue>
            <PerformanceDetails>
              ({formatCount(shelf.click)} / {formatCount(shelf.shown)})
            </PerformanceDetails>
          </PerformanceItem>
          <PerformanceItem>
            <PerformanceLabel>スクロール率</PerformanceLabel>
            <PerformanceValue>
              {formatPercentage(shelf.scrollr)}%
            </PerformanceValue>
            <PerformanceDetails>
              ({formatCount(shelf.scroll)} / {formatCount(shelf.shown)})
            </PerformanceDetails>
          </PerformanceItem>
        </PerformanceContainer>
      )}
      {shelf.uiType !== ShelfUiTypeEnum.gridBanner && renderItems()}
    </ShelfCardContainer>
  );
};

export default Shelf;

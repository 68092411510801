import React, { useState } from 'react';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Paper, Alert, CircularProgress } from '@mui/material';
import { useAuth } from '../../store/auth.store';
import { loginWithGoogle } from '../../services/auth-service';

const LoginPage: React.FC = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const onSuccess = async (credentialResponse: CredentialResponse) => {
    setIsLoading(true);
    try {
      const user = await loginWithGoogle(credentialResponse.credential || '');
      login({
        ...user,
        token: credentialResponse.credential || '',
      });
      navigate('/dashboard/shelf-previewer');
    } catch (err) {
      console.error('Login Failed:', err);
      setError('Googleログインに失敗しました。もう一度お試しください。');
    } finally {
      setIsLoading(false);
    }
  };

  const onError = () => {
    console.log('Login Failed');
    setError('Googleログインに失敗しました。もう一度お試しください。');
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper elevation={3} sx={{ padding: 4, borderRadius: 2, width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <img src="insightx-logo.png" alt="InsightX ロゴ" width="200" />
        </Box>
        {error && (
          <Box sx={{ mb: 2, marginBottom: '30px' }}>
            <Alert severity="error" onClose={() => setError(null)}>
              {error}
            </Alert>
          </Box>
        )}
        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <GoogleLogin
              onSuccess={onSuccess}
              onError={onError}
              useOneTap
              theme="outline"
              shape="pill"
              size="large"
            />
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default LoginPage;

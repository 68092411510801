import api from './api';
import { IUser } from '../types/user.type';

export const getToken = async (): Promise<string | null> => {
  const user = localStorage.getItem('ix_dashboard_user');
  if (user) {
    const parsedUser: IUser = JSON.parse(user);
    return parsedUser.token;
  }
  return null;
};

export const loginWithGoogle = async (token: string): Promise<IUser> => {
  const response = await api.post('/auth/google', {
    token,
  });
  return response.data;
};

export enum ShelfTypeEnum {
  foryou = 'foryou',
  perspective = 'perspective',
  perspective2 = 'perspective2',
  perspective3 = 'perspective3',
  perspective4 = 'perspective4',
  perspective5 = 'perspective5',
  oneItem = 'one_item',
  history = 'history',
  related = 'related',
  brand = 'brand',
  category = 'category',
  subCategory = 'sub_category',
  sale = 'sale',
  restock = 'restock',
  magazine = 'magazine',
  goodReviewScore = 'good_review_score',
  favoriteTrend = 'favorite_trend',
  popularTrend = 'popular_trend',
  newPopular = 'new_popular',
  viewedBrand = 'viewed_brand',
  viewedCategory = 'viewed_category',
  topRanking = 'top_ranking',
  search = 'search',
  newArrival = 'new_arrival',
  searchKeyword = 'search_keyword',
  coordForyou = 'coord_foryou',
  coordSearch = 'coord_search',
}

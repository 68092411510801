import React, { useEffect } from 'react';
import { useAuth } from '../store/auth.store';

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { login } = useAuth();

  useEffect(() => {
    const storedUser = localStorage.getItem('ix_dashboard_user');
    if (storedUser) {
      login(JSON.parse(storedUser));
    }
  }, [login]);

  return <>{children}</>;
};

import React, { useState, useEffect } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import { useAuth } from '../../store/auth.store';
import { useClient } from '../../store/client.store';

const drawerWidth = 240;
const miniDrawerWidth = 60;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: miniDrawerWidth,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface MenuItem {
  name: string;
  icon: React.ReactNode;
  path: string;
  subItems?: MenuItem[];
}

interface DashboardLayoutProps {
  children: React.ReactNode;
  menuItems: MenuItem[];
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  menuItems,
}) => {
  const [open, setOpen] = useState(false);
  const [openGroups, setOpenGroups] = useState<{ [key: string]: boolean }>({});
  const { auth: authState, logout } = useAuth();
  const { client: clientState, setSelectedClient } = useClient();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState('Dashboard');

  useEffect(() => {
    const currentMenuItem = menuItems.find((item) =>
      location.pathname.startsWith(item.path),
    );
    if (currentMenuItem) {
      setCurrentPage(currentMenuItem.name);
    }
  }, [location, menuItems]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const clientIdFromUrl = searchParams.get('clientId');
    if (
      clientIdFromUrl &&
      clientIdFromUrl !== clientState.selectedClient?.clientId
    ) {
      setSelectedClient(clientIdFromUrl);
    }
  }, [location.search, clientState.selectedClient, setSelectedClient]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleGroupToggle = (name: string) => {
    setOpenGroups((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const handleClientChange = (event: SelectChangeEvent<string>) => {
    const newClientId = event.target.value;
    setSelectedClient(newClientId);
  };

  const renderMenuItem = (item: MenuItem, depth = 0) => {
    if (item.subItems) {
      return (
        <React.Fragment key={item.name}>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              onClick={() => handleGroupToggle(item.name)}
              sx={{ pl: 2 + depth * 2 }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              {open && <ListItemText primary={item.name} />}
              {open &&
                (openGroups[item.name] ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
          </ListItem>
          <Collapse in={openGroups[item.name]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.subItems.map((subItem) =>
                renderMenuItem(subItem, depth + 1),
              )}
            </List>
          </Collapse>
        </React.Fragment>
      );
    }

    return (
      <ListItem key={item.name} disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          component={Link}
          to={`${item.path}?clientId=${clientState.selectedClient?.clientId}`}
          sx={{ pl: 2 + depth * 2 }}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          {open && <ListItemText primary={item.name} />}
        </ListItemButton>
      </ListItem>
    );
  };

  if (clientState.isLoading || authState.isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/')}
            >
              <img
                src="/insightx-logo.png"
                alt="InsightX Logo"
                style={{ height: '40px', marginRight: '16px' }}
              />
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ fontFamily: 'Noto Sans JP, sans-serif', fontWeight: 700 }}
              >
                {currentPage}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {clientState.clients.length > 1 ? (
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="client-select-label">Client</InputLabel>
                <Select
                  labelId="client-select-label"
                  id="client-select"
                  value={clientState.selectedClient?.clientId || ''}
                  label="Client"
                  onChange={handleClientChange}
                >
                  {clientState.clients.map((client) => (
                    <MenuItem key={client.clientId} value={client.clientId}>
                      {client.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Typography sx={{ mr: 2 }}>
                {clientState.selectedClient?.name}
              </Typography>
            )}
            <Button
              onClick={handleLogout}
              variant="contained"
              sx={{
                backgroundColor: '#FF9933',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#FF8000',
                },
              }}
            >
              ログアウト
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <DrawerHeader />
        <Divider />
        <List sx={{ marginTop: '10px' }}>
          {menuItems.map((item) => renderMenuItem(item))}
        </List>
      </Drawer>
      <Box component="main" sx={{ width: '97%', marginTop: '10px' }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};

export default DashboardLayout;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import {
  Button,
  IconButton,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import styled from '@emotion/styled';
import { IShelfOrder } from '../../types/shelf-order.type';
import { ShelfTypeEnum } from '../../enums/shelf-type.enum';
import { RecommendLogicEnum } from '../../enums/recommend-logic.enum';
import FolderIcon from '@mui/icons-material/Folder';
import ViewListIcon from '@mui/icons-material/ViewList';
import HistoryIcon from '@mui/icons-material/History';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarIcon from '@mui/icons-material/Star';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import CategoryIcon from '@mui/icons-material/Category';
import SearchIcon from '@mui/icons-material/Search';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import BookIcon from '@mui/icons-material/Book';
import CheckroomIcon from '@mui/icons-material/Checkroom';

const FormRow = styled.div<{ depth: number }>`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 16px;
  padding-left: ${(props) => props.depth * 20}px;
  align-items: center;
`;

const ShelfContainer = styled(Paper)<{ depth: number }>`
  padding: 16px;
  margin-bottom: 16px;
  margin-left: ${(props) => props.depth * 20}px;
  background-color: #f0f0f0;
`;

const shelfTypeNames: { [key in ShelfTypeEnum]: string } = {
  [ShelfTypeEnum.brand]: 'ブランド',
  [ShelfTypeEnum.category]: 'カテゴリ',
  [ShelfTypeEnum.subCategory]: 'サブカテゴリ',
  [ShelfTypeEnum.search]: 'こだわり',
  [ShelfTypeEnum.newArrival]: '新着おすすめ',
  [ShelfTypeEnum.newPopular]: '新着人気',
  [ShelfTypeEnum.viewedBrand]: '閲覧ブランド',
  [ShelfTypeEnum.viewedCategory]: '閲覧カテゴリ',
  [ShelfTypeEnum.sale]: 'セール',
  [ShelfTypeEnum.restock]: '再入荷',
  [ShelfTypeEnum.magazine]: '雑誌掲載',
  [ShelfTypeEnum.goodReviewScore]: '高評価',
  [ShelfTypeEnum.favoriteTrend]: 'お気に入り急上昇',
  [ShelfTypeEnum.popularTrend]: '人気急上昇',
  [ShelfTypeEnum.topRanking]: '人気上位',
  [ShelfTypeEnum.perspective]: 'シェルフ群',
  [ShelfTypeEnum.perspective2]: 'シェルフ群2',
  [ShelfTypeEnum.perspective3]: 'シェルフ群3',
  [ShelfTypeEnum.perspective4]: 'シェルフ群4',
  [ShelfTypeEnum.perspective5]: 'シェルフ群5',
  [ShelfTypeEnum.history]: '閲覧履歴',
  [ShelfTypeEnum.foryou]: 'あなたへのおすすめ',
  [ShelfTypeEnum.oneItem]: '一商品フォーカス',
  [ShelfTypeEnum.related]: '関連商品',
  [ShelfTypeEnum.searchKeyword]: '検索キーワード',
  [ShelfTypeEnum.coordForyou]: 'コーディネートおすすめ',
  [ShelfTypeEnum.coordSearch]: 'コーディネートこだわり',
};

const isPerspectiveShelf = (shelfType: ShelfTypeEnum): boolean => {
  return [
    ShelfTypeEnum.perspective,
    ShelfTypeEnum.perspective2,
    ShelfTypeEnum.perspective3,
    ShelfTypeEnum.perspective4,
    ShelfTypeEnum.perspective5,
  ].includes(shelfType);
};

const getShelfTypeOptions = (
  depth: number,
  baseType: 'user-based' | 'item-based',
): ShelfTypeEnum[] => {
  if (depth === 0) {
    if (baseType === 'user-based') {
      return [
        ShelfTypeEnum.history,
        ShelfTypeEnum.foryou,
        ShelfTypeEnum.oneItem,
        ShelfTypeEnum.coordForyou,
        ShelfTypeEnum.perspective,
        ShelfTypeEnum.perspective2,
        ShelfTypeEnum.perspective3,
        ShelfTypeEnum.perspective4,
        ShelfTypeEnum.perspective5,
      ];
    } else if (baseType === 'item-based') {
      return [
        ShelfTypeEnum.related,
        ShelfTypeEnum.perspective,
        ShelfTypeEnum.perspective2,
        ShelfTypeEnum.perspective3,
        ShelfTypeEnum.perspective4,
        ShelfTypeEnum.perspective5,
      ];
    }
  } else {
    return [
      ShelfTypeEnum.brand,
      ShelfTypeEnum.category,
      ShelfTypeEnum.subCategory,
      ShelfTypeEnum.search,
      ShelfTypeEnum.newArrival,
      ShelfTypeEnum.newPopular,
      ShelfTypeEnum.viewedBrand,
      ShelfTypeEnum.viewedCategory,
      ShelfTypeEnum.sale,
      ShelfTypeEnum.restock,
      ShelfTypeEnum.magazine,
      ShelfTypeEnum.goodReviewScore,
      ShelfTypeEnum.favoriteTrend,
      ShelfTypeEnum.popularTrend,
      ShelfTypeEnum.topRanking,
      ShelfTypeEnum.searchKeyword,
      ShelfTypeEnum.coordSearch,
    ];
  }
  return [];
};

// ShelfTypeEnum に対応するアイコンを定義
const shelfTypeIcons: { [key in ShelfTypeEnum]: React.ReactNode } = {
  [ShelfTypeEnum.brand]: <LocalOfferIcon />,
  [ShelfTypeEnum.category]: <CategoryIcon />,
  [ShelfTypeEnum.subCategory]: <CategoryIcon />,
  [ShelfTypeEnum.search]: <SearchIcon />,
  [ShelfTypeEnum.newArrival]: <NewReleasesIcon />,
  [ShelfTypeEnum.newPopular]: <WhatshotIcon />,
  [ShelfTypeEnum.viewedBrand]: <HistoryIcon />,
  [ShelfTypeEnum.viewedCategory]: <HistoryIcon />,
  [ShelfTypeEnum.sale]: <LocalOfferIcon />,
  [ShelfTypeEnum.restock]: <NewReleasesIcon />,
  [ShelfTypeEnum.magazine]: <BookIcon />,
  [ShelfTypeEnum.goodReviewScore]: <StarIcon />,
  [ShelfTypeEnum.favoriteTrend]: <FavoriteIcon />,
  [ShelfTypeEnum.popularTrend]: <TrendingUpIcon />,
  [ShelfTypeEnum.topRanking]: <StarIcon />,
  [ShelfTypeEnum.perspective]: <FolderIcon />,
  [ShelfTypeEnum.perspective2]: <FolderIcon />,
  [ShelfTypeEnum.perspective3]: <FolderIcon />,
  [ShelfTypeEnum.perspective4]: <FolderIcon />,
  [ShelfTypeEnum.perspective5]: <FolderIcon />,
  [ShelfTypeEnum.history]: <HistoryIcon />,
  [ShelfTypeEnum.foryou]: <FavoriteIcon />,
  [ShelfTypeEnum.oneItem]: <ViewListIcon />,
  [ShelfTypeEnum.related]: <ViewListIcon />,
  [ShelfTypeEnum.searchKeyword]: <SearchIcon />,
  [ShelfTypeEnum.coordForyou]: <CheckroomIcon />,
  [ShelfTypeEnum.coordSearch]: <CheckroomIcon />,
};

interface ShelfOrderFormProps {
  shelfOrder: IShelfOrder;
  onUpdate: (updatedShelfOrder: IShelfOrder) => void;
  onRemove: () => void;
  depth?: number;
  baseType: 'user-based' | 'item-based';
  baseRecommendLogic?: RecommendLogicEnum;
}

const ShelfOrderForm: React.FC<ShelfOrderFormProps> = ({
  shelfOrder,
  onUpdate,
  onRemove,
  depth = 0,
  baseType,
  baseRecommendLogic,
}) => {
  const [localShelfOrder, setLocalShelfOrder] = useState<IShelfOrder>({
    ...shelfOrder,
    maxItems: shelfOrder.maxItems || 50,
    tagline2Template: shelfOrder.tagline2Template || '',
  });

  // shelfOrder が変更されたときに localShelfOrder を更新
  useEffect(() => {
    setLocalShelfOrder({
      ...shelfOrder,
      maxItems: shelfOrder.maxItems || 50,
      tagline2Template: shelfOrder.tagline2Template || '',
    });
  }, [shelfOrder]);

  const handleChange = (field: keyof IShelfOrder, value: any) => {
    const updatedShelfOrder = { ...localShelfOrder, [field]: value };
    if (field === 'shelfType') {
      if (baseType === 'item-based') {
        updatedShelfOrder.baseShelfOrders = [
          {
            shelfType: ShelfTypeEnum.related,
            shelfNum: 1,
            recommendLogic: baseRecommendLogic,
          },
        ];
      }
      if (isPerspectiveShelf(value)) {
        if (
          !updatedShelfOrder.shelfOrders ||
          updatedShelfOrder.shelfOrders.length === 0
        ) {
          const newChildShelfOrder: IShelfOrder = {
            shelfType: ShelfTypeEnum.brand,
            shelfNum: 1,
            maxItems: 50,
            tagline2Template: '',
          };
          if (baseType === 'item-based') {
            newChildShelfOrder.baseShelfOrders = [
              {
                shelfType: ShelfTypeEnum.related,
                shelfNum: 1,
                recommendLogic: baseRecommendLogic,
              },
            ];
          }
          updatedShelfOrder.shelfOrders = [newChildShelfOrder];
        }
      } else {
        updatedShelfOrder.shelfOrders = [];
      }
    }
    setLocalShelfOrder(updatedShelfOrder);
    onUpdate(updatedShelfOrder);
  };

  const handleAddChildShelf = () => {
    const newChildShelfOrder: IShelfOrder = {
      shelfType: ShelfTypeEnum.brand,
      shelfNum: 1,
      maxItems: 50,
      tagline2Template: '',
    };
    if (baseType === 'item-based') {
      newChildShelfOrder.baseShelfOrders = [
        {
          shelfType: ShelfTypeEnum.related,
          shelfNum: 1,
          recommendLogic: baseRecommendLogic,
        },
      ];
    }
    handleChange('shelfOrders', [
      ...(localShelfOrder.shelfOrders || []),
      newChildShelfOrder,
    ]);
  };

  const getShelfTitle = () => {
    if (isPerspectiveShelf(localShelfOrder.shelfType)) {
      return shelfTypeNames[localShelfOrder.shelfType];
    }
    return `${shelfTypeNames[localShelfOrder.shelfType]}シェルフ`;
  };

  const IconComponent = shelfTypeIcons[localShelfOrder.shelfType] || (
    <ViewListIcon fontSize="small" />
  );

  return (
    <ShelfContainer depth={depth}>
      <FormRow depth={depth}>
        {IconComponent}
        <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 'bold' }}>
          {getShelfTitle()}
        </Typography>
        <IconButton
          onClick={onRemove}
          color="error"
          aria-label="シェルフを削除"
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      </FormRow>
      <FormRow depth={depth}>
        <FormControl style={{ flex: 2 }}>
          <InputLabel>シェルフタイプ</InputLabel>
          <Select
            value={localShelfOrder.shelfType}
            onChange={(e) =>
              handleChange('shelfType', e.target.value as ShelfTypeEnum)
            }
            label="シェルフタイプ"
            size="small"
          >
            {getShelfTypeOptions(depth, baseType).map((type) => (
              <MenuItem key={type} value={type}>
                {shelfTypeNames[type]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ flex: 1 }}>
          <InputLabel>シェルフ数</InputLabel>
          <Select
            value={localShelfOrder.shelfNum}
            onChange={(e) =>
              handleChange('shelfNum', parseInt(e.target.value as string, 10))
            }
            label="シェルフ数"
            size="small"
          >
            {Array.from({ length: 101 }, (_, i) => (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {isPerspectiveShelf(localShelfOrder.shelfType) && (
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleAddChildShelf}
            size="small"
            style={{ marginTop: 8 }}
          >
            シェルフを追加
          </Button>
        )}
      </FormRow>
      {isPerspectiveShelf(localShelfOrder.shelfType) &&
        localShelfOrder.shelfOrders?.map((childShelfOrder, index) => (
          <ShelfOrderForm
            key={index}
            shelfOrder={childShelfOrder}
            onUpdate={(updatedChildShelfOrder) => {
              const updatedChildShelfOrders = localShelfOrder.shelfOrders?.map(
                (so, i) => (i === index ? updatedChildShelfOrder : so),
              );
              handleChange('shelfOrders', updatedChildShelfOrders);
            }}
            onRemove={() => {
              const updatedChildShelfOrders =
                localShelfOrder.shelfOrders?.filter((_, i) => i !== index);
              handleChange('shelfOrders', updatedChildShelfOrders);
            }}
            depth={depth + 1}
            baseType={baseType}
            baseRecommendLogic={baseRecommendLogic}
          />
        ))}
    </ShelfContainer>
  );
};

export default ShelfOrderForm;

import {
  IPostRecommendsReq,
  IPostRecommendsRes,
} from '../../types/post-recommends.type';
import api from '../api';

export const postRecommends = async (
  dto: IPostRecommendsReq,
): Promise<IPostRecommendsRes> => {
  const res = await api.post(`/recommends/recommends`, dto);
  return res.data;
};

import axios from 'axios';
import { clientAtom } from '../store/client.store';
import { getDefaultStore } from 'jotai';
import { authAtom } from '../store/auth.store';

const store = getDefaultStore();

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
});

api.interceptors.request.use(
  async (config) => {
    if (config.url?.endsWith('/auth/google')) {
      return config;
    }

    config.headers['Content-Type'] = 'application/json';

    const { user } = store.get(authAtom);
    if (user && user.token) {
      config.headers['Authorization'] = `Bearer ${user.token}`;
    }

    const clientState = store.get(clientAtom);
    const selectedClientId = clientState.selectedClient?.clientId;
    if (selectedClientId) {
      config.headers['X-IX-Client-ID'] = selectedClientId;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      // Dispatch logout action
      store.set(authAtom, { type: 'logout' });
    }
  },
);

export default api;

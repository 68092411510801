import React, { useEffect } from 'react';
import { useClient } from '../store/client.store';
import { IClient } from '../types/client.type';

export const ClientProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { client, setClients } = useClient();

  useEffect(() => {
    const fetchClients = async () => {
      try {
        // const response = await api.get('/clients');
        const mockClients: IClient[] = [
          {
            clientId: 'can-1c558cb2-089b-4176-ae80-0df05def6848',
            name: 'キャン様',
          },
          {
            clientId: 'onward-a1737baa-85fc-458b-9951-0205f9c26311',
            name: 'オンワード様',
          },
          {
            clientId: 'pal-7e490282-fe4b-4260-b635-cb387b175f27',
            name: 'パル様',
          },
          {
            clientId: 'belluna-ca666883-c786-43ab-95a3-026f42b1ac15',
            name: 'ベルーナ様',
          },
          {
            clientId: 'marui-d612f1eb-3528-46da-9ebe-5c68545b07e1',
            name: '丸井様',
          },
          {
            clientId: 'urban-7d72e1bd-e6ef-4057-bc8c-2f4779f5a2b6',
            name: 'アーバンリサーチ様',
          },
        ];
        setClients(mockClients);
      } catch (error) {
        console.error('Failed to fetch clients:', error);
      }
    };
    fetchClients();
  }, [setClients]);

  if (client.isLoading) {
    return <div>Loading clients...</div>;
  }

  return <>{children}</>;
};

import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { IUser } from '../types/user.type';
import { useCallback } from 'react';

const userAtom = atomWithStorage<IUser | null>('ix_dashboard_user', null);
export const isAuthenticatedAtom = atom((get) => get(userAtom) !== null);
export const isLoadingAtom = atom(true);

export const authAtom = atom(
  (get) => ({
    user: get(userAtom),
    isAuthenticated: get(isAuthenticatedAtom),
    isLoading: get(isLoadingAtom),
  }),
  (
    get,
    set,
    action: { type: 'login'; payload: IUser } | { type: 'logout' },
  ) => {
    switch (action.type) {
      case 'login':
        set(userAtom, action.payload);
        set(isLoadingAtom, false);
        break;
      case 'logout':
        set(userAtom, null);
        set(isLoadingAtom, false);
        break;
    }
  },
);

export const useAuth = () => {
  const [auth, dispatch] = useAtom(authAtom);

  const login = useCallback(
    (user: IUser) => {
      dispatch({ type: 'login', payload: user });
    },
    [dispatch],
  );

  const logout = useCallback(() => {
    dispatch({ type: 'logout' });
  }, [dispatch]);

  return { auth, login, logout };
};

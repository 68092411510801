import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { ConfigUtil } from './config/config-util';
import PersonIcon from '@mui/icons-material/Person';
import DashboardLayout from './components/layout/DashboardLayout';
import { AuthProvider } from './providers/AuthProvider';
import { ClientProvider } from './providers/ClientProvider';
import LoginPage from './components/pages/LoginPage';
import PrivateRoute from './components/route/PrivateRoute';
import ShelfPreviewerPage from './components/shelf-previewer/ShelfPreviewerPage';
import ItemShelfPreviewer from './components/item-shelf-previewer/ItemShelfPreviewer';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import SearchIcon from '@mui/icons-material/Search';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ListShelfPreviewer from './components/list-shelf-previewer/ListShelfPreviewer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ShelfViewer from './components/shelf-viewer/ShelfViewer';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#FF6600',
    },
    secondary: {
      main: '#FF9933',
    },
    background: {
      default: '#FAFAFA',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#333333',
    },
  },
  typography: {
    fontFamily: '"Noto Sans JP", "Helvetica", "Arial", sans-serif',
  },
});

const menuItems = [
  {
    name: 'ユーザーシェルフ',
    icon: <PersonIcon />,
    path: '/dashboard/shelf-previewer',
  },
  {
    name: '商品ベースシェルフ',
    icon: <ShoppingBagIcon />,
    path: '/dashboard/item-shelf-previewer',
  },
  {
    name: '検索ページシェルフ',
    icon: <SearchIcon />,
    path: '/dashboard/list-shelf-previewer',
  },
  {
    name: 'シェルフビューアー',
    icon: <ViewModuleIcon />,
    path: '/dashboard/shelf-viewer',
  },
];

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={ConfigUtil.getGoogleCliendId()}>
          <AuthProvider>
            <BrowserRouter>
              <ClientProvider>
                <Routes>
                  <Route path="/login" element={<LoginPage />} />
                  <Route element={<PrivateRoute />}>
                    <Route
                      path="/dashboard/*"
                      element={
                        <DashboardLayout menuItems={menuItems}>
                          <Routes>
                            <Route
                              path="shelf-previewer"
                              element={<ShelfPreviewerPage />}
                            />
                          </Routes>
                          <Routes>
                            <Route
                              path="item-shelf-previewer"
                              element={<ItemShelfPreviewer />}
                            />
                          </Routes>
                          <Routes>
                            <Route
                              path="list-shelf-previewer"
                              element={<ListShelfPreviewer />}
                            />
                          </Routes>
                          <Routes>
                            <Route
                              path="shelf-viewer"
                              element={<ShelfViewer />}
                            />
                          </Routes>
                        </DashboardLayout>
                      }
                    />
                  </Route>
                  <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
              </ClientProvider>
            </BrowserRouter>
          </AuthProvider>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;

import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { IShelf } from '../../types/shelf.type';
import ShelfItemHeatmap from './ShelfItemHeatmap';
import Shelf from '../shelf/Shelf';

interface ShelfN1UserProps {
  shelf: IShelf;
}

const ShelfN1User: React.FC<ShelfN1UserProps> = ({ shelf }) => {
  return (
    <Box
      sx={{
        margin: '20px 0',
        padding: '10px 20px 30px 20px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        position: 'relative',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      }}
    >
      <Avatar
        sx={{
          position: 'absolute',
          top: '-20px',
          left: '20px',
          backgroundColor: 'primary.main',
          width: 40,
          height: 40,
        }}
      >
        <PersonIcon />
      </Avatar>
      <Typography
        sx={{
          marginLeft: '0px',
          marginTop: '15px',
          marginBottom: '20px',
          fontWeight: 700,
          fontSize: '15px',
          color: '#333',
          fontFamily: '"Noto Sans JP", sans-serif',
          letterSpacing: '0.05em',
          borderBottom: '2px solid #3f51b5',
          paddingBottom: '5px',
          display: 'inline-block',
        }}
      >
        N1商品閲覧履歴
      </Typography>
      <Shelf shelf={shelf} />
      <ShelfItemHeatmap shelf={shelf} groupBy="category" />
      <Box sx={{ margin: '15px 0' }} />
      <ShelfItemHeatmap shelf={shelf} groupBy="brand" />
    </Box>
  );
};

export default ShelfN1User;

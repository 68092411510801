import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useClient } from '../../store/client.store';
import { IShelf } from '../../types/shelf.type';
import {
  CircularProgress,
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
} from '@mui/material';
import styled from 'styled-components';
import Shelf from '../shelf/Shelf';
import SelectedShelvesDrawer from './SelectedShelvesDrawer';
import { IShelfPerformance } from '../../types/shelf-performance.type';
import { IItem } from '../../types/item.type';
import { fetchShelfData } from './shlefDataUtils';

const GridContainer = styled(Grid)`
  margin-top: 20px;
`;

const RankingCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const RankingList = styled(Box)`
  overflow-y: auto;
  flex-grow: 1;
`;

const LoadMoreButton = styled.button`
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #ff6600;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const ShelfViewer: React.FC = () => {
  const { client: clientState } = useClient();
  const [performances, setPerformances] = useState<IShelfPerformance[]>([]);
  const [itemsMetadata, setItemsMetadata] = useState<{ [aid: string]: IItem }>(
    {},
  );
  const [selectedShelves, setSelectedShelves] = useState<IShelf[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleCentroids, setVisibleCentroids] = useState(3);
  const [visibleShelves, setVisibleShelves] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const { performances, itemsMetadata } = await fetchShelfData(
          clientState.selectedClient.clientId,
        );
        setPerformances(performances);
        setItemsMetadata(itemsMetadata);
        const initialVisibleShelves = performances.reduce(
          (acc, performance) => {
            acc[`${performance.centroidId}-${performance.period}`] = 3;
            return acc;
          },
          {},
        );
        setVisibleShelves(initialVisibleShelves);
      } catch (error) {
        console.error('Error loading shelf data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, [clientState.selectedClient]);

  const handleClearAllShelves = () => {
    setSelectedShelves([]);
  };

  const handleSelectShelf = useCallback(
    (shelf: IShelf, isSelected: boolean) => {
      setSelectedShelves((prev) =>
        isSelected
          ? [...prev, shelf]
          : prev.filter((s) => s.shelfId !== shelf.shelfId),
      );
    },
    [],
  );

  const handleRemoveShelf = useCallback((shelfId: string) => {
    setSelectedShelves((prev) => prev.filter((s) => s.shelfId !== shelfId));
  }, []);

  const groupedPerformances = useMemo(() => {
    const grouped: { [centroidId: string]: IShelfPerformance[] } = {};
    performances.forEach((performance) => {
      if (!grouped[performance.centroidId]) {
        grouped[performance.centroidId] = [];
      }
      grouped[performance.centroidId].push(performance);
    });
    return grouped;
  }, [performances]);

  const handleLoadMoreShelves = useCallback(
    (centroidId: string, period: string) => {
      setVisibleShelves((prev) => ({
        ...prev,
        [`${centroidId}-${period}`]:
          (prev[`${centroidId}-${period}`] || 3) + 10,
      }));
    },
    [],
  );

  const handleLoadMoreCentroids = useCallback(() => {
    setVisibleCentroids((prev) => prev + 3);
  }, []);

  const renderRankingCard = (performance: IShelfPerformance) => {
    const sortedShelves = [...performance.shelves].sort((a, b) => {
      const minShown = 300;

      // 表示数が500以下のシェルフを最後に
      if (a.shown <= minShown && b.shown > minShown) return 1;
      if (b.shown <= minShown && a.shown > minShown) return -1;

      // 両方とも表示数が500以下の場合は、スクロール率で比較
      if (a.shown <= minShown && b.shown <= minShown) {
        return (b.scrollr || 0) - (a.scrollr || 0);
      }

      // それ以外の場合は、スクロール率で比較
      return (b.scrollr || 0) - (a.scrollr || 0);
    });
    const visibleShelvesCount =
      visibleShelves[`${performance.centroidId}-${performance.period}`] || 3;

    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        key={`${performance.centroidId}-${performance.period}`}
      >
        <RankingCard>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {performance.period}の表示数ランキング
            </Typography>
            <RankingList>
              {sortedShelves
                .slice(0, visibleShelvesCount)
                .map((shelf, index) => (
                  <Shelf
                    key={shelf.shelfId}
                    shelf={{
                      ...shelf,
                      items: shelf.items.map((item) => ({
                        ...itemsMetadata[item.aid],
                        ...item,
                      })),
                      ranking: index + 1,
                    }}
                    onSelectShelf={handleSelectShelf}
                    isSelected={selectedShelves.some(
                      (s) => s.shelfId === shelf.shelfId,
                    )}
                  />
                ))}
            </RankingList>
            {visibleShelvesCount < sortedShelves.length && (
              <LoadMoreButton
                onClick={() =>
                  handleLoadMoreShelves(
                    performance.centroidId,
                    performance.period,
                  )
                }
              >
                もっと見る
              </LoadMoreButton>
            )}
          </CardContent>
        </RankingCard>
      </Grid>
    );
  };

  return (
    <Box>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <GridContainer container spacing={2}>
          {Object.entries(groupedPerformances)
            .slice(0, visibleCentroids)
            .map(([centroidId, centroidPerformances]) => (
              <React.Fragment key={centroidId}>
                <Grid item xs={12}>
                  <Typography variant="h5">クラスタ: {centroidId}</Typography>
                </Grid>
                {centroidPerformances.map((performance) =>
                  renderRankingCard(performance),
                )}
              </React.Fragment>
            ))}
        </GridContainer>
      )}
      {visibleCentroids < Object.keys(groupedPerformances).length && (
        <LoadMoreButton onClick={handleLoadMoreCentroids}>
          もっとクラスタを見る
        </LoadMoreButton>
      )}
      <SelectedShelvesDrawer
        selectedShelves={selectedShelves}
        onRemoveShelf={handleRemoveShelf}
        onClearAllShelves={handleClearAllShelves}
      />
    </Box>
  );
};

export default ShelfViewer;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from 'react';
import { IBrandItem, IBrandItemItem } from '../../types/brand-item.type';
import { IShelfConfig } from '../../types/shelf-config.type';
import styled from '@emotion/styled';
import {
  Autocomplete,
  TextField,
  Avatar,
  Box,
  Typography,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { postRecommends } from '../../services/shelf-previewer/post-recommends';
import { PageTypeEnum } from '../../enums/page-type.enum';
import { useClient } from '../../store/client.store';
import Shelf from '../shelf/Shelf';
import { useDebounce } from 'use-debounce';
import { useQuery } from '@tanstack/react-query';
import { IPostRecommendsRes } from '../../types/post-recommends.type';

interface BrandCardProps {
  brandItem: IBrandItem;
  shelfConfig: IShelfConfig;
}

const SmartphoneFrame = styled.div`
  margin: auto;
  width: 450px;
  height: 1200px;
  border: 16px solid #ddd;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
  overflow-y: auto;
  position: relative;
  background: white;

  /* スクロールバーを非表示にするスタイル */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    display: none; /* WebKit */
  }

  &::before {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -32px;
    left: 50%;
    transform: translateX(-50%);
    background: #9e9e9e;
    border-radius: 10px;
  }

  &::after {
    content: '';
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: -48px;
    left: 50%;
    transform: translateX(-50%);
    background: #9e9e9e;
    border-radius: 50%;
  }
`;

const BrandCardStyled = styled.div`
  width: 100%;
  min-width: 0;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const BrandTitle = styled.h2`
  color: #ff6600;
  margin-bottom: 10px;
`;

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`;

const BrandCard: React.FC<BrandCardProps> = ({ brandItem, shelfConfig }) => {
  const [selectedItem, setSelectedItem] = useState<IBrandItemItem | null>(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const { client: clientState } = useClient();
  const sessionRef = useRef<string>(
    `session-${Math.random().toString(36).substr(2, 9)}`,
  );

  // 初期選択アイテムの設定（brandItemが変更されたときのみ実行）
  useEffect(() => {
    if (brandItem.items.length > 0) {
      const initialItem = brandItem.items[0];
      setSelectedItem(initialItem);
    }
  }, [brandItem]);

  // デバウンスされた selectedItem と shelfConfig
  const [debouncedSelectedItem] = useDebounce(selectedItem, 500);
  const [debouncedShelfConfig] = useDebounce(shelfConfig, 500);

  // Recommendations の取得
  const {
    data: recommendations,
    isLoading,
    error,
  } = useQuery<IPostRecommendsRes>({
    queryKey: [
      'recommendations',
      debouncedSelectedItem?.aid,
      debouncedShelfConfig,
    ],
    queryFn: () => {
      const postReq = {
        clientId: clientState.selectedClient.clientId,
        pageType: PageTypeEnum.Pdp,
        session: sessionRef.current,
        seedAids: [debouncedSelectedItem!.aid],
        shelfConfig: debouncedShelfConfig,
      };
      return postRecommends(postReq);
    },
    enabled: !!debouncedSelectedItem,
  });

  const handleChange = (event: any, newValue: IBrandItemItem | null) => {
    setSelectedItem(newValue);
  };

  const handleCopyAid = () => {
    if (selectedItem) {
      navigator.clipboard.writeText(selectedItem.aid).then(
        () => {
          setCopySuccess(true);
        },
        (err) => {
          console.error('aidのコピーに失敗しました:', err);
        },
      );
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setCopySuccess(false);
  };

  // オプションのフィルタリングをカスタマイズ
  const filterOptions = (options: IBrandItemItem[], { inputValue }: any) => {
    return options.filter((option) =>
      `${option.aid} ${option.itemTitle} ${brandItem.brandName}`
        .toLowerCase()
        .includes(inputValue.toLowerCase()),
    );
  };

  return (
    <BrandCardStyled>
      <BrandTitle>{brandItem.brandName}のアイテム選択</BrandTitle>
      <SelectContainer>
        {/* aidをコピーするためのアイコンボタン */}
        <IconButton
          onClick={handleCopyAid}
          size="small"
          disabled={!selectedItem}
          sx={{ marginRight: 1 }}
          aria-label="Copy aid"
        >
          <ContentCopyIcon />
        </IconButton>
        {/* オートコンプリートコンポーネント */}
        <Autocomplete
          value={selectedItem}
          onChange={handleChange}
          options={brandItem.items}
          getOptionLabel={(option) => option.itemTitle}
          isOptionEqualToValue={(option, value) => option.aid === value.aid}
          filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              label="アイテムを選択"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                startAdornment: selectedItem ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginRight: 1,
                      gap: 1,
                    }}
                  >
                    {selectedItem.imageUrl && (
                      <Avatar
                        src={selectedItem.imageUrl}
                        alt={selectedItem.itemTitle}
                        sx={{ width: 30, height: 30 }}
                        variant="square"
                      />
                    )}
                    <Typography variant="body2">
                      PV: {selectedItem.pvCount7d}
                    </Typography>
                  </Box>
                ) : null,
              }}
            />
          )}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {option.imageUrl && (
                <Avatar
                  src={option.imageUrl}
                  alt={option.itemTitle}
                  sx={{ width: 40, height: 40, marginRight: 2 }}
                  variant="square"
                />
              )}
              <Box>
                <Typography variant="body1">{option.itemTitle}</Typography>
                <Typography variant="caption" color="textSecondary">
                  PV数: {option.pvCount7d}
                </Typography>
              </Box>
            </Box>
          )}
          fullWidth
        />
      </SelectContainer>
      {/* 推薦結果の表示 */}
      {isLoading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">エラーが発生しました。</Typography>
      ) : (
        <SmartphoneFrame>
          {recommendations &&
            recommendations.shelves.map((shelf, index) => (
              <div key={index}>
                <Shelf shelf={shelf} />
              </div>
            ))}
        </SmartphoneFrame>
      )}
      {/* Snackbar for copy confirmation */}
      <Snackbar
        open={copySuccess}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: '100%' }}
        >
          aidをコピーしました！
        </Alert>
      </Snackbar>
    </BrandCardStyled>
  );
};

export default BrandCard;

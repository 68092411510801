import React from 'react';
import { IItem } from '../../types/item.type';
import styled from 'styled-components';
import { IShelf } from '../../types/shelf.type';
import { ShelfTypeEnum } from '../../enums/shelf-type.enum';

interface ItemProps {
  shelf: IShelf;
  item: IItem;
  onImageError: () => void;
  isHighlighted: boolean;
}

const Aid = styled.div`
  font-size: 12px;
  color: #666;
  margin-bottom: 2px;
`;

const ItemCard = styled.a`
  color: inherit;
  text-decoration: none;
  position: relative;
`;

const ItemImage = styled.img<{ isCoord: boolean }>`
  width: 100%;
  height: auto;
  border-radius: 5px;

  ${(props) =>
    props.isCoord &&
    `
    width: 120px;
    height: 160px;
    object-fit: cover;
  `}
`;

const ItemTitle = styled.h5`
  font-size: 12px;
  margin: 3px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const ItemDetail = styled.p`
  font-size: 10px;
  color: #666;
  margin: 1px 0;
`;

const ItemContainer = styled.div<{ isCoord: boolean }>`
  flex: 0 0 auto;
  width: 120px;
  color: inherit;
`;

const ItemPvCount = styled.div`
  font-size: 10px;
  color: #666;
  margin-bottom: 2px;
`;

const CreatedAt = styled.p.attrs<{ isHighlighted: boolean }>(() => ({}))`
  font-size: 10px;
  color: ${(props) => (props.isHighlighted ? '#ff6600' : '#666')};
  margin: 1px 0;
  font-weight: ${(props) => (props.isHighlighted ? 'bold' : 'normal')};
`;

const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  bottom: 5px;
  left: 5px;
  background: rgba(255, 255, 255, 0.8);
  padding: 2px 5px;
  border-radius: 5px;
`;

const UserImage = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
`;

const UserTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelName = styled.span`
  font-size: 10px;
  color: #333;
  font-weight: bold;
`;

const UserName = styled.span`
  font-size: 9px;
  color: #666;
`;

const Item: React.FC<ItemProps> = ({
  shelf,
  item,
  onImageError,
  isHighlighted,
}) => {
  const isCoord = [
    ShelfTypeEnum.coordForyou,
    ShelfTypeEnum.coordSearch,
  ].includes(shelf.shelfType);
  return (
    <ItemContainer isCoord={isCoord}>
      <Aid>{item.aid}</Aid>
      <ItemCard href={item.url} target="_blank" rel="noreferrer">
        <ItemImage
          src={item.imageUrl}
          alt={item.title}
          loading="lazy"
          onError={onImageError}
          isCoord={isCoord}
        />
        {isCoord && item.userImageUrl && (
          <UserInfoContainer>
            <UserImage src={item.userImageUrl} alt={item.userName} />
            <UserTextContainer>
              {item.labelName && <LabelName>{item.labelName}</LabelName>}
              {item.userName && <UserName>{item.userName}</UserName>}
            </UserTextContainer>
          </UserInfoContainer>
        )}
        {item.title && <ItemTitle>{item.title}</ItemTitle>}
        {item.brand && <ItemDetail>{item.brand}</ItemDetail>}
        {item.price && <ItemDetail>{item.price} 円</ItemDetail>}
        {item.pv_count_7d && (
          <ItemPvCount>
            pv数：{item.pv_count_7d} ({item.pv_count_7d_rank}位)
          </ItemPvCount>
        )}
        {item.itemShown && <ItemPvCount>表示：{item.itemShown}回</ItemPvCount>}
        {shelf.shelfType === ShelfTypeEnum.history && item.createdAt && (
          <CreatedAt isHighlighted={isHighlighted}>
            {new Date(item.createdAt).toLocaleString()}
          </CreatedAt>
        )}
      </ItemCard>
    </ItemContainer>
  );
};

export default Item;

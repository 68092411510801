import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { IconButton, Typography, Button, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IShelf } from '../../types/shelf.type';

interface SelectedShelvesDrawerProps {
  selectedShelves: IShelf[];
  onRemoveShelf: (shelfId: string) => void;
  onClearAllShelves: () => void;
}

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`;

const DrawerContainer = styled.div<{ $isVisible: boolean }>`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  max-height: 300px;
  background-color: white;
  border-radius: 8px;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 1000;
  transition: transform 0.1s ease-in-out;
  transform: translateY(${({ $isVisible }) => ($isVisible ? '0' : '100%')});
  animation: ${({ $isVisible }) =>
    $isVisible
      ? css`
          ${slideIn} 0.1s ease-in-out
        `
      : css`
          ${slideOut} 0.1s ease-in-out
        `};
`;

const DrawerContent = styled.div`
  padding: 20px;
`;

const ShelfItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
`;

const CopyButton = styled(Button)`
  margin-top: 20px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SelectedShelvesDrawer: React.FC<SelectedShelvesDrawerProps> = ({
  selectedShelves,
  onRemoveShelf,
  onClearAllShelves,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (selectedShelves.length > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [selectedShelves]);

  const generatePrompt = () => {
    const shelfInfos = selectedShelves
      .map((shelf) => {
        const items = shelf.items.slice(0, 10);
        const itemDescriptions = items
          .map(
            (item) =>
              `  - ${item.title}（${item.price}円）ブランド:${item.brand},カテゴリ:${item.category},サブカテゴリ:${item.subCategory}`,
          )
          .join('\n');

        return `
シェルフタイトル: ${shelf.tagline2}
商品情報:
${itemDescriptions}`;
      })
      .join('\n');

    return `以下の情報を元に、ECサイトのファッション記事のタイトルを5つ考えてください。これらのシェルフすべてを記事内部に表示したいです。記事のタイトルは、シェルフにちゃんと合致されているものにしてください。記事タイトルは、シェルフすべてに当てはまるものにしてください。

${shelfInfos}

1. 記事タイトル
  例)
  - AUTUMN OUTFIT 「羽織る、秋」
  - 1枚で着映える 秋ブラウス
  - "素材"で選ぶ秋の新作コレクション
  - 欲しいを見つけるLIFE STYLE GOODS
  - シーズンムードを楽しむ、今すぐ使える秋トップス

また、季節感や最新のファッショントレンドを考慮に入れてください。それぞれのアイデアは、読者が実際に購買行動を起こしたくなるような魅力的な内容にしてください。各シェルフの特徴や、シェルフ間の関連性にも注目してアイデアを考えてください。`;
  };

  const handleCopyPrompt = () => {
    const prompt = generatePrompt();
    navigator.clipboard.writeText(prompt).then(() => {
      setSnackbarOpen(true);
    });
  };

  const handleClearAll = () => {
    onClearAllShelves();
  };

  return (
    <>
      <DrawerContainer $isVisible={isVisible}>
        <DrawerContent>
          <ButtonsContainer>
            <CopyButton
              variant="contained"
              color="primary"
              sx={{ color: 'white', fontSize: '12px' }}
              startIcon={<ContentCopyIcon />}
              onClick={handleCopyPrompt}
            >
              記事作成プロンプトコピー
            </CopyButton>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClearAll}
              sx={{ marginLeft: '10px', padding: '0px 10px', minWidth: '0px' }}
            >
              <CloseIcon sx={{ width: '15px', height: '15px' }} />
            </Button>
          </ButtonsContainer>
          {selectedShelves.map((shelf) => (
            <ShelfItem key={shelf.shelfId}>
              <Typography variant="body1">{shelf.tagline2}</Typography>
              <IconButton
                onClick={() => onRemoveShelf(shelf.shelfId)}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </ShelfItem>
          ))}
        </DrawerContent>
      </DrawerContainer>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message="プロンプトがコピーされました"
        autoHideDuration={2000}
        sx={{ color: 'white' }}
      />
    </>
  );
};

export default SelectedShelvesDrawer;
